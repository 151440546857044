<template>
  <div class="dimension-classic">
    <div class="df-row">
      <div class="df-col">
        <!-- 门店选择器 -->
        <el-select
          v-if="authType == 'H'"
          v-model="submitData.StoresGuids"
          multiple
          collapse-tags
          clearable
          size="mini"
          placeholder="请选择门店"
          @change="onChangeStores"
        >
          <el-option
            v-for="item in storesList"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-select
          v-model="submitData.DimensionGuid"
          placeholder="维度分类"
          size="mini"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="">查询</el-button>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onCustomDialog"
          >自定义表格</el-button
        >
      </div>
    </div>

    <el-table
      ref="table"
      id="exprot-table"
      border
      stripe
      :data="dataList"
      :max-height="tableMaxHeight"
    >
      <!-- 合并列表头（一级表头），由于表格需要自定义，只能依靠循环渲染列的数据，而列的循环顺序则依赖后端接口返回的data顺序，
      所以如果需要修改渲染出来的顺序就要让后端改 -->
      <el-table-column
        v-for="(item, index) in topList"
        :key="index"
        :label="item.Name"
        align="center"
      >
        <!-- 子表头（二级表头） -->
        <el-table-column
          v-if="item.Code == item2.TopCode"
          v-for="(item2, index2) in headList"
          :key="index2"
          :label="item2.Name"
          :prop="item2.DataKey"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div>{{ scope.row[item2.DataKey] }}</div>
            <div>
              {{
                scope.row[item2.DataKeyPlus] != undefined
                  ? "(" + scope.row[item2.DataKeyPlus] + ")"
                  : ""
              }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>

    <!-- start 自定义表格对话框 -->
    <CustomDialog ref="customDialog" @onConfirm=""></CustomDialog>
    <!-- end 自定义表格对话框 -->
  </div>
</template>

<script>
import CustomDialog from "@/views/report/components/custom-dialog.vue";

export default {
  components: { CustomDialog },

  data() {
    return {
      tableMaxHeight: "",
      totalRecord: 0,

      submitData: {
        BeginDate: "",
        EndDate: "",
        StoresGuid: "",
        DimensionGuid: "",
      },
      total: {},
      headData: {}, // 自定义对话框表头源数据（尽可能不要污染）
      heads: [], // 自定义对话框的表头列表
      topList: [], // 表格的合并表头（一级表头）
      headList: [], // 表格的表头（二级表头）
      storesList: [], // 门店列表
      dataList: [], // 表格内容数据列表
      multipleDate: [],
      loading: false,
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);
    let accountInfo = this.storage.getStorage("accountInfo");
    this.authType = accountInfo.authType;
    this.storesList = "";

    this.initDateState();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取表头设置
    async fetchEmplBasicClassStatis(type = 0) {
      if (!type) {
        try {
          let { data } = await report.fetchEmplBasicClassStatis();
          let { Heads, HeadTops } = data;
          this.headData = data;
          this.heads = Heads;
        } catch (e) {}
      }
    },

    // 初始化表格数据信息
    async initPageData() {
      try {
        let { data } = await report.queryEmplBasicClassStatis(this.submitData);
        let { DataList, HeadList, TopList, Total } = data;
        await this.tableColumnMaxWidth(HeadList);
        this.dataList = await DataList;
        this.headList = await HeadList;
        this.topList = await TopList;
        this.total = Total;
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.multipleDate = [beginDay, endDay];
      this.submitData.BeginDate = beginDay;
      this.submitData.EndDate = endDay;
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 60);
    },

    onChangeStores() {},

    // 打开自定义表格对话框
    onCustomDialog() {
      this.$refs.customDialog.onShowDialog(this.headData);
    },
  },
};
</script>

<style lang="less" scoped>
.dimension-classic {
  .df-row {
    margin-block-start: 20px;
  }
}
</style>
